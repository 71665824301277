<template>
    <div>
        <div class="container">
            <!--<video playsinline autoplay loop muted class="video-parallax">
                <source src="../assets/Durance2020V3.webm" type="video/webm">
            </video>-->
            <img src="../assets/Title_New.jpeg" class="img-parallax"/>
        </div>
        <div class="headercontainer">
            <div>
                <h1>Kanuzeichen</h1>
            </div>
        </div>
        <div class="top-div-decoration">
        </div>
        <div class="container">
            <img class="fixedimage" src="../assets/zeichen_und_bedeutungen-1.jpg" />
        </div>
        <div class="container">
          <img class="fixedimage scaled" src="../assets/zeichen_und_bedeutungen-2.jpg" />
        </div>
        <div class="bottom-div-decoration">
        </div>
    </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<style scoped>

@supports (-webkit-text-stroke: 1px black) {
  .headercontainer h1 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h3 {
    -webkit-text-stroke: 2px #2c3e50;
  }
  .headercontainer h6 {
    -webkit-text-stroke: 0.5px #2c3e50;
  }
} 


.headercontainer{
  background-color: transparent;
  display:flex;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

@media screen and (max-width: 600px){
  .headercontainer{
    background-color: transparent;
    display:flex;
    /*background-color: rgba(80, 80, 80, 0.6);*/
    padding-top:40px;
  }
}

.headercontainer h1{
  color: #f2f2f2;
}

video{
  width: 100%;
  z-index: -100;
}

.video-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
}

.img-parallax {
    -webkit-transition-position: fixed;
    position: fixed;
    z-index: -100;
    height: 100%;
    width: 100%;
    object-fit:cover;
}

.headercontainer > div{
  background-color:transparent;
  flex:1;
  color: white;
  font-size: 25px;
}

.container{
  background-color: #fff;
  display:flex;
}

.container:first-child{
  height: 10vh;
  background-color: rgba(80, 80, 80, 0.0);
}

.container > div{
  flex:1;
  background-color:transparent;
  color: #2c3e50;
  font-size: 16px;
  margin: 25px 8vw 10px 8vw;
}

.clear{
  background-color: transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.container div p{
  font-weight: bold;
}

.top-div-decoration{
  border-bottom: 75px solid white;
  border-right: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.top-div-decoration h1{
  position: absolute;
  left: 100px;
  font-size: 80px;
}

.bottom-div-decoration {
  border-top: 75px solid white;
  border-left: 100vw solid transparent;
  /*background-color: rgba(80, 80, 80, 0.6);*/
}

.bottom-div-decoration-text{
  position: absolute;
  right: 120px;
  font-size: 80px;
  margin-top: 0px;
}

.fixedimage{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
}

@media screen and (max-width: 600px){
  .fixedimage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }
  .fixedimage.scaled{
    width: 90vw;
  }
}

.scaled{
  padding-top: 50px;
  padding-left: 26px;
  width: 50vw;
}
</style>
